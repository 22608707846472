import { ResultsChartIcon } from '@mentimeter/ragnar-visuals';
import { buildPresentationPath } from '@mentimeter/presentation-url-utils';
import { Strong } from '@mentimeter/ragnar-ui/typography';
import { Text } from '@mentimeter/ragnar-ui/text';
import {
  getTimeAgo,
  NotificationBody,
  NotificationContainer,
  NotificationLink,
  NotificationIcon,
  NotificationHeader,
} from '../../notification';
import { truncate } from '../../notification/utils/truncate';
import type { SeriesResultsNotificationT } from './schema';

export const SeriesResultsNotification = ({
  notification: {
    id,
    timestamp,
    payload: { seriesId, seriesName, numberOfVotes },
    status,
  },
  onClick,
}: {
  notification: SeriesResultsNotificationT;
  onClick: () => void;
}) => {
  const truncatedName = truncate(seriesName);

  return (
    <NotificationLink
      href={buildPresentationPath({
        seriesId,
        mode: 'results',
      })}
      onClick={onClick}
    >
      <NotificationContainer key={id}>
        <NotificationHeader>
          <NotificationIcon
            icon={<ResultsChartIcon size="20px" color="onInfoWeak" />}
          />
        </NotificationHeader>
        <NotificationBody status={status}>
          <Text>
            You’ve got new results for{' '}
            <Strong fontWeight="semiBold">{truncatedName}</Strong>.
          </Text>
          <Text color="textWeaker">{getTimeAgo(timestamp)} ago</Text>
        </NotificationBody>
      </NotificationContainer>
    </NotificationLink>
  );
};
