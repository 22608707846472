import * as React from 'react';
import { DrawerContent } from './DrawerContent';
import type { DrawerContentProps } from './DrawerContent';
import { DrawerOverlay } from './DrawerOverlay';
import { DrawerPortal } from './DrawerPortal';

interface DrawerContainerProps extends DrawerContentProps {
  withOverlay?: boolean;
}

export const DrawerContainer = React.forwardRef<
  HTMLDivElement,
  DrawerContainerProps
>(({ withOverlay = true, ...rest }, forwardedRef) => (
  <DrawerPortal>
    {withOverlay && <DrawerOverlay />}
    <DrawerContent ref={forwardedRef} {...rest} />
  </DrawerPortal>
));
