import * as React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Box } from '../box';
import { Text } from '../text';

export interface EmbedT {
  title: string;
  src: string;
  allowFullScreen?: boolean;
}

export class Embed extends React.PureComponent<EmbedT, { loading: boolean }> {
  container = React.createRef<HTMLIFrameElement>();
  override state = { loading: true };

  override componentDidMount() {
    if (this.container.current) {
      this.container.current.addEventListener('load', this.handleLoad);
    }
  }

  UNSAFE_componentWillUnmount() {
    if (this.container.current) {
      this.container.current.removeEventListener('load', this.handleLoad);
    }
  }

  handleLoad = () => {
    this.setState({ loading: false });
  };

  override render() {
    const { loading } = this.state;
    const { src, allowFullScreen, title } = this.props;
    return (
      <Box
        className={clsx([
          'w-full',
          'h-0',
          'pb-[56.25%]',
          'overflow-hidden',
          'rounded',
          'bg-neutral-weakest',
          '[&>iframe]:absolute',
          '[&>iframe]:top-0',
          '[&>iframe]:left-0',
          '[&>iframe]:w-full',
          '[&>iframe]:h-full',
        ])}
      >
        {loading && (
          <Box
            className={clsx([
              'absolute',
              'top-0',
              'left-0',
              'w-full',
              'h-full',
              'items-center',
              'justify-center',
            ])}
          >
            <Text variant="ragnarBodySm" className="text-weaker">
              Loading embed...
            </Text>
          </Box>
        )}
        <iframe
          ref={this.container}
          title={title}
          src={src}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={allowFullScreen}
        />
      </Box>
    );
  }
}
