import type { BoxT } from '@mentimeter/ragnar-ui/box';
import { Box } from '@mentimeter/ragnar-ui/box';
import type { ButtonT } from '@mentimeter/ragnar-ui/button';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Heading, Meta } from '@mentimeter/ragnar-ui/typography';

export type HeroT = BoxT & {
  title: string;
  description: string;
  action: ButtonT & {
    key: string;
  };
};

export const Hero = ({ title, description, action, ...rest }: HeroT) => {
  return (
    <Box width="100%" alignItems="center" mb={4} {...rest}>
      <Heading>{title}</Heading>
      <Meta>{description}</Meta>
      {action && <Button size="large" mt={[3]} {...action} />}
    </Box>
  );
};
