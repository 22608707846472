import { CheckIcon } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Text } from '@mentimeter/ragnar-ui/text';

export const NotificationCenterHeading = ({
  onClick,
}: {
  onClick?: () => void;
}) => {
  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
      alignItems="center"
      px="space4"
      pt="space2"
    >
      <Box flexDirection="row" gap="space2">
        <Text fontSize="125" lineHeight="150">
          Notifications
        </Text>
      </Box>
      {onClick && (
        <Button
          variant="secondary"
          onClick={onClick}
          iconLeading={CheckIcon}
          size="compact"
        >
          Mark all as read
        </Button>
      )}
    </Box>
  );
};
