import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { NotificationCenterHeading } from './NotificationCenterHeading';

export function NotificationCenterEmptyState() {
  return (
    <>
      <NotificationCenterHeading />
      <Box
        justifyContent="center"
        alignItems="center"
        bg="infoWeakest"
        p="space8"
        mx="space4"
        mb="space3"
        borderRadius="lg"
      >
        <Text
          fontSize="100"
          fontWeight="semiBold"
          mb="space0.5"
          textAlign="center"
          color="onInfoWeakest"
        >
          Nothing to catch up on for now
        </Text>
        <Text fontSize="100" textAlign="center" color="onInfoWeakest">
          You don’t have any notifications.
        </Text>
      </Box>
    </>
  );
}
