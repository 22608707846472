import { Warning } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { NotificationCenterHeading } from './NotificationCenterHeading';

export function NotificationCenterErrorState() {
  return (
    <>
      <NotificationCenterHeading />
      <Box
        justifyContent="center"
        alignItems="center"
        bg="noticeWeakest"
        px="space8"
        pt="space8"
        pb="space14"
        mb="space4"
        mx="space4"
        borderRadius="lg"
      >
        <Box width={80} height={80} justifyContent="center" alignItems="center">
          <Warning variant="blue" />
        </Box>
        <Text
          fontSize="100"
          fontWeight="semiBold"
          mb="space0.5"
          textAlign="center"
          color="onNoticeWeakest"
        >
          Can’t load notifications
        </Text>
        <Text fontSize="100" textAlign="center" color="onNoticeWeakest">
          Try again later or contact customer support.
        </Text>
      </Box>
    </>
  );
}
