import { getIsMemberLite, useUser } from '@mentimeter/user';
import { animated, type SpringValues } from 'react-spring';
import React, { useRef, useState } from 'react';
import { useOutsideClick } from '@mentimeter/react-hooks';
import {
  TrackingPlacement,
  type TrackingContext,
} from '@mentimeter/http-clients';
import { useEngagementLimits } from '@mentimeter/paywalls-data-hooks';
import { Badge } from '@mentimeter/ragnar-ui/badge';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';
import {
  PopoverBody,
  PopoverContainer,
  PopoverRoot,
  PopoverTrigger,
} from '@mentimeter/ragnar-ui/popover';
import { Text } from '@mentimeter/ragnar-ui/text';
import { getResetsAtText } from '../../../lib/hooks/get-resets-at-text';
import { shouldShowEngagementLimitProgress } from '../../shouldShowEngagementLimitProgress';
import { useProgressBarAnimation } from '../../use-progress-bar-animation';
import { RequestUpgradePopover } from '../../../lib/request-upgrade';
import { EngagementLimitProgressUpgradeButton } from '../../../buttons';

const WIDTH = 224;

export function EngagementLimitProgressBubble({
  trackingContext,
}: {
  trackingContext: TrackingContext | undefined;
}) {
  const { user } = useUser();
  const engagementLimits = useEngagementLimits();
  const isMemberLite = getIsMemberLite(user);

  if (!user || !engagementLimits) {
    return null;
  }

  const {
    engagementStatus,
    engagementData: { limit, progress, resetsAt },
  } = engagementLimits;

  if (!shouldShowEngagementLimitProgress(user.createdAt, engagementStatus))
    return null;

  if (isMemberLite) {
    return (
      <EngagementLimitProgressBubbleRequestUpgrade
        limit={limit}
        progress={progress}
        resetsAt={resetsAt}
        trackingContext={trackingContext}
      />
    );
  }

  return (
    <EngagementLimitProgressBubbleComponent
      limit={limit}
      progress={progress}
      showUpgradeBadge={false}
      resetsAt={resetsAt}
      trackingContext={trackingContext}
    />
  );
}

function EngagementLimitProgressBubbleRequestUpgrade({
  progress,
  limit,
  resetsAt,
  trackingContext,
}: {
  progress: number;
  limit: number;
  resetsAt: Date;
  trackingContext: TrackingContext | undefined;
}) {
  const hasReachedLimit = progress >= limit;
  const formattedTimeUntilResets = getResetsAtText(resetsAt);
  const resetsDateText = `Your free limit resets ${formattedTimeUntilResets}`;
  const limitDescriptionText = hasReachedLimit
    ? 'Request an upgrade or wait until your limit resets to gather more responses'
    : 'You can go over the limit once every month, or you can request an upgrade now';
  const description = `${resetsDateText}. ${limitDescriptionText}.`;

  return (
    <RequestUpgradePopover
      title="Unlock unlimited participants"
      description={description}
      trackingContext={trackingContext}
      trackingPlacement={TrackingPlacement.EngagementLimitsProgressBar}
    >
      <EngagementLimitProgressBubbleComponent
        limit={limit}
        progress={progress}
        showUpgradeBadge={false}
        resetsAt={resetsAt}
        trackingContext={trackingContext}
      />
    </RequestUpgradePopover>
  );
}

function EngagementLimitProgressBubbleComponent({
  progress,
  limit,
  showUpgradeBadge,
  resetsAt,
  trackingContext,
}: {
  progress: number;
  limit: number;
  resetsAt: Date;
  showUpgradeBadge: boolean;
  trackingContext: TrackingContext | undefined;
}) {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [clicked, setClicked] = useState(false);

  const hasReachedLimit = progress >= limit;

  useOutsideClick(['#engagement-progress'], () => {
    setClicked(false);
  });

  const formattedTimeUntilResets = getResetsAtText(resetsAt);
  const resetsDateText = `Your free limit resets ${formattedTimeUntilResets}`;
  const limitDescriptionText = hasReachedLimit
    ? 'Upgrade or wait until your limit resets to gather more responses'
    : 'We will not interrupt your next presentation, you can go over the limit once every month';

  return (
    <PopoverRoot>
      <PopoverTrigger>
        <Clickable
          id="engagement-progress"
          width={WIDTH}
          zIndex={3}
          ref={contentRef}
          borderRadius="2xl"
          py="space3"
          px="space4"
          bg="bg"
          extend={({ theme }) => ({
            cursor: clicked ? 'default' : 'pointer',
            '@media (hover: hover)': {
              ':hover': {
                opacity: 1,
                backgroundColor: theme.colors.surfaceHover,
              },
            },
          })}
        >
          <ParticipantsProgress
            limit={limit}
            progress={progress}
            showUpgradeBadge={showUpgradeBadge}
          />
        </Clickable>
      </PopoverTrigger>
      <PopoverContainer
        showArrow={false}
        align="end"
        sideOffset={12}
        maxWidth="400px"
        extend={() => ({
          boxShadow: undefined,
          filter:
            'drop-shadow(rgba(0, 0, 0, 0.02) 0px 4px 4px) drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 1px) drop-shadow(rgba(0, 0, 0, 0.05) 0px 1px 1px) drop-shadow(rgba(0, 0, 0, 0.02) 0px 14px 32px)',
        })}
        gap="space4"
      >
        <PopoverBody overflow="auto" alignItems="stretch" p="space6" m="space0">
          <Text
            fontSize="125"
            lineHeight="snug"
            fontWeight="regular"
            color="text"
          >
            {resetsDateText}
          </Text>

          <Text fontSize="100" lineHeight="normal" color="textWeak" mt="space2">
            {limitDescriptionText}
          </Text>
        </PopoverBody>

        <EngagementLimitProgressUpgradeButton
          trackingContext={trackingContext}
          onClick={(e) => {
            e.stopPropagation();
            setClicked(false);
          }}
        />
      </PopoverContainer>
    </PopoverRoot>
  );
}

function ParticipantsProgress({
  progress,
  limit,
  showUpgradeBadge,
}: {
  progress: number;
  limit: number;
  showUpgradeBadge: boolean;
}) {
  const { style, progressByLimit } = useProgressBarAnimation(progress, limit);

  return (
    <Box width="100%" alignItems="stretch">
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="baseline"
        gap="space1.5"
      >
        <Text fontSize="75" lineHeight="100" fontWeight="semiBold" color="text">
          Participants this month
        </Text>

        <Box flexDirection="row" alignItems="baseline">
          <Text fontSize="87.5" fontWeight="bold" color="text">
            {progress}
          </Text>

          <Text fontSize="62.5">
            <Text fontSize="62.5" mx="space0.5">
              /
            </Text>
            {limit}
          </Text>

          {showUpgradeBadge && <Badge ml="space1.5" compact type="upgrade" />}
        </Box>
      </Box>
      <Box height="4px" position="relative" mt="space2">
        <Box
          bg="neutralWeakest"
          borderRadius="default"
          extend={() => ({
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
          })}
        />
        <Box
          as={animated.div}
          data-testid="engagement-progress"
          data-progress={progressByLimit}
          bg="secondary"
          borderRadius="default"
          style={style as SpringValues}
          extend={() => ({
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
          })}
        />
      </Box>
    </Box>
  );
}
