import { SettingsIcon } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Text } from '@mentimeter/ragnar-ui/text';
import { NotificationCenterHeading } from './NotificationCenterHeading';

export function NotificationCenterTurnedOffState() {
  return (
    <>
      <NotificationCenterHeading />
      <Box
        justifyContent="center"
        alignItems="center"
        bg="infoWeakest"
        p="space8"
        mx="space4"
        mb="space2"
        borderRadius="lg"
      >
        <Text
          fontSize="100"
          fontWeight="semiBold"
          mb="space0.5"
          textAlign="center"
          color="onInfoWeakest"
        >
          Notifications are turned off
        </Text>
        <Text fontSize="100" textAlign="center" color="onInfoWeakest">
          Change your preferences in settings to get updates.
        </Text>
      </Box>
      <Box alignItems="flex-end" mx="space4" mb="space3">
        <Button
          variant="primary"
          href="/app/settings"
          iconLeading={SettingsIcon}
        >
          Go to settings
        </Button>
      </Box>
    </>
  );
}
