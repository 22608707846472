import * as React from 'react';
import { Content } from '@radix-ui/react-dialog';
import type { DialogContentProps } from '@radix-ui/react-dialog';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import {
  variants,
  type DrawerContentVariantProps,
} from './DrawerContent.variants';
import './drawer-keyframes.css';

export interface DrawerContentProps
  extends DialogContentProps,
    DrawerContentVariantProps {
  dismissable?: boolean;
}

export const DrawerContent = React.forwardRef<
  HTMLDivElement,
  DrawerContentProps
>(
  (
    {
      dismissable = true,
      onPointerDownOutside,
      onEscapeKeyDown,
      className,
      side = 'bottom',
      ...rest
    },
    forwardedRef,
  ) => {
    const handlePointerDownOutside: DialogContentProps['onPointerDownOutside'] =
      (e) => {
        if (insideToasts(e.target)) e.preventDefault();
        if (!dismissable) e.preventDefault();
        if (onPointerDownOutside) onPointerDownOutside(e);
      };
    const handleEscapeKeyDown: DialogContentProps['onEscapeKeyDown'] = (e) => {
      if (!dismissable) e.preventDefault();
      if (onEscapeKeyDown) onEscapeKeyDown(e);
    };
    return (
      <Content
        ref={forwardedRef}
        aria-describedby={undefined}
        onPointerDownOutside={handlePointerDownOutside}
        onEscapeKeyDown={handleEscapeKeyDown}
        className={cn('DrawerContent', variants({ side }), className)}
        data-side={side}
        {...rest}
      />
    );
  },
);

function insideToasts(element: any) {
  const toasts = document.getElementById('toasts');
  return toasts?.contains(element);
}
