import { cva, type VariantProps } from '@mentimeter/ragnar-tailwind-config';

export const variants = cva(
  [
    'fixed',
    'bg',
    'flex',
    'flex-col',
    'items-stretch',
    'max-h-full',
    'max-w-full',
    'border-weak',
    'border-solid',
    'overflow-hidden',
    'z-modal',
    'focus:outline-none',
    'gap-2',
  ],
  {
    variants: {
      side: {
        left: ['top-0', 'left-0', 'h-full', 'w-11/12', 'border-r-[2px]'],
        right: ['top-0', 'right-0', 'h-full', 'w-11/12', 'border-l-[2px]'],
        top: [
          'top-0',
          'w-full',
          'h-auto',
          'rounded-b-2xl',
          'border-b-[2px]',
          'border-x-[2px]',
        ],
        bottom: [
          'bottom-0',
          'w-full',
          'h-auto',
          'rounded-t-2xl',
          'border-t-[2px]',
          'border-x-[2px]',
        ],
      },
    },
  },
);

export type DrawerContentVariantProps = VariantProps<typeof variants>;
