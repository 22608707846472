import React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Box } from '../box';

interface DrawerBodyT {
  className?: string;
  children: React.ReactNode;
}

export const DrawerBody = React.forwardRef<HTMLDivElement, DrawerBodyT>(
  ({ className, children, ...rest }, forwardedRef) => (
    <Box
      ref={forwardedRef}
      className={clsx(
        ['w-full', 'h-full', 'flex-auto', 'overflow-auto', 'px-8', 'pb-4'],
        className,
      )}
      {...rest}
    >
      {children}
    </Box>
  ),
);
