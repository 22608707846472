import { MentimeterSymbol } from '@mentimeter/ragnar-visuals';
import { Text } from '@mentimeter/ragnar-ui/text';
import {
  getTimeAgo,
  NotificationBody,
  NotificationContainer,
  NotificationLink,
} from '../../notification';
import type { NearingEngagementLimitNotificationT } from './schema';

export const NearingEngagementLimitNotification = ({
  notification: {
    id,
    timestamp,
    payload: { limit, engagedParticipantLimit },
    status,
  },
  onClick,
}: {
  notification: NearingEngagementLimitNotificationT;
  onClick: () => void;
}) => {
  return (
    <NotificationLink href="/app/manage-members" onClick={onClick}>
      <NotificationContainer key={id}>
        <MentimeterSymbol width={40} />
        <NotificationBody status={status}>
          <Text>
            You are nearing the engagement limit of {limit} participants.{' '}
            {engagedParticipantLimit} participants are already engaged.{' '}
            <strong>Upgrade</strong> to engage more participants.
          </Text>
          <Text color="textWeaker">{getTimeAgo(timestamp)} ago</Text>
        </NotificationBody>
      </NotificationContainer>
    </NotificationLink>
  );
};
