import { Box } from '@mentimeter/ragnar-ui/box';
import { Embed } from '@mentimeter/ragnar-ui/embed';

export const GettingStarted = () => (
  <Box width="100%" display="flex">
    <Embed
      title="Getting started"
      src="https://player.vimeo.com/video/332646721?byline=0"
      allowFullScreen={true}
    />
  </Box>
);
