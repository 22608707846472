import { Embed } from '@mentimeter/ragnar-ui/embed';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Meta, P, SubHeading } from '@mentimeter/ragnar-ui/typography';

export const Powerpoint = () => (
  <>
    <Box
      alignItems="stretch"
      flexDirection={['column', null, 'row']}
      width="100%"
    >
      <Box width={[1, null, 1 / 2]}>
        <Embed
          title="Make your Powerpoint interactive"
          src="https://player.vimeo.com/video/330468456?byline=0"
        />
      </Box>
      <Box
        ml={[0, null, 3]}
        mt={[3, null, 0]}
        justifyContent="center"
        flex="1 1 auto"
        width={1}
      >
        <SubHeading>Make your Powerpoint interactive</SubHeading>
        <Meta>
          Do you have a Powerpoint presentation but want to use the amazing
          interactivity Mentimeter has to offer? Look no further, in this short
          tutorial video, we show you how you can upload your presentation to
          Mentimeter to add polls, quizzes and more!
        </Meta>
      </Box>
    </Box>
    <Box alignItems="start" width="100%">
      <SubHeading mt={4}>
        Use Mentimeter directly in your PowerPoint presentation
      </SubHeading>
      <P>
        The Powerpoint plugin enables you to embed Mentimeter question slides
        into your Powerpoint presentation. The plugin will work on any Windows
        computer running PowerPoint 2016. You need an Office 365 account to
        download the plugin.
      </P>
      <Button
        size="large"
        href="https://appsource.microsoft.com/en-us/product/office/WA104379261"
        target="_blank"
        rel="noopener noreferrer"
        variant="primary"
        mt={3}
      >
        Get plugin
      </Button>
      <Meta mt={1}>
        Please note that we recommend using Mentimeter in your web browser for
        the best experience.
      </Meta>
    </Box>
  </>
);
