import {
  HomeIcon,
  MentiIcon,
  ProjectorIcon,
  SmartphoneIcon,
  TrendIcon,
  StarIcon,
  UsersIcon,
  QuestionMarkIcon,
} from '@mentimeter/ragnar-visuals';
import { trackUser } from '@api/tracking/client';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Action } from '@mentimeter/ragnar-ui/action';
import { SubHeading, P } from '@mentimeter/ragnar-ui/typography';
import { Hero } from '../hero';

const isEven = (x: number) => x % 2 === 0;

const isOdd = (x: number) => !isEven(x);

const HELP_ACTIONS = [
  {
    icon: HomeIcon,
    href: 'https://help.mentimeter.com/en/collections/75492-getting-started-with-mentimeter',
    'aria-label': 'Go to Getting started with Mentimeter',
    title: 'Getting started with Mentimeter',
  },
  {
    icon: MentiIcon,
    href: 'https://help.mentimeter.com/creating-presentations-and-questions?_ga=2.36871648.1117751669.1561983297-1418963565.1554109325',
    'aria-label': 'Go to Creating presentations and questions',
    title: 'Creating presentations and questions',
  },
  {
    icon: ProjectorIcon,
    href: 'https://help.mentimeter.com/presenting-with-mentimeter?_ga=2.36871648.1117751669.1561983297-1418963565.1554109325',
    'aria-label': 'Go to Presenting with Mentimeter',
    title: 'Presenting with Mentimeter',
  },
  {
    icon: SmartphoneIcon,
    href: 'https://help.mentimeter.com/how-to-vote?_ga=2.36871648.1117751669.1561983297-1418963565.1554109325',
    'aria-label': 'Go to How to vote',
    title: 'How to vote',
  },
  {
    icon: TrendIcon,
    href: 'https://help.mentimeter.com/after-the-presentation?_ga=2.71406163.1117751669.1561983297-1418963565.1554109325',
    'aria-label': 'Go to After the presentation',
    title: 'After the presentation',
  },
  {
    icon: StarIcon,
    href: 'https://help.mentimeter.com/pricing-and-payment-information?_ga=2.71406163.1117751669.1561983297-1418963565.1554109325',
    'aria-label': 'Go to Pricing and payment information',
    title: 'Pricing and payment information',
  },
  {
    icon: UsersIcon,
    href: 'https://help.mentimeter.com/team-and-account-management?_ga=2.71406163.1117751669.1561983297-1418963565.1554109325',
    'aria-label': 'Go to Team and account management',
    title: 'Team and account management',
  },
  {
    icon: QuestionMarkIcon,
    href: 'https://help.mentimeter.com/faq?_ga=2.71406163.1117751669.1561983297-1418963565.1554109325',
    'aria-label': 'Go to Frequently asked questions',
    title: 'FAQ',
  },
];

const trackHelpCenter = (props: { link: string; context: string }) => {
  trackUser({
    event: 'Clicked go to help center',
    properties: { placement: 'Help modal', ...props },
  });
};

export const HelpCenter = ({ context }: { context: string }) => {
  // @ts-expect-error-auto TS(7006) FIXME: Parameter 'title' implicitly has an 'any' type.
  const goToHelpCenter = (title, href) => {
    trackHelpCenter({ link: title, context });
    window.open(href, '_blank');
  };

  return (
    <>
      <Hero
        title="Feeling stuck?"
        description="Check out our Help center"
        action={{
          variant: 'primary',
          onClick: () =>
            goToHelpCenter('Go to Help center', 'https://help.mentimeter.com'),
          key: 'help-center',
          children: 'Go to Help center',
        }}
      />
      <SubHeading>Browse by category</SubHeading>
      <Box width="100%" flexDirection={['column', 'row']} flexWrap="wrap">
        {HELP_ACTIONS.map(({ title, icon: I, href }, i) => (
          <Box
            key={title}
            width={[1, 1 / 2]}
            pr={[0, isEven(i) ? 2 : 0]}
            pl={[0, isOdd(i) ? 2 : 0]}
            mb={3}
          >
            <Action
              p={3}
              onClick={() => goToHelpCenter(title, href)}
              aria-label={title}
              extend={({}) => ({ borderWidth: '1px' })}
            >
              <Box alignItems="center">
                <I size={5} color="primary" />
                <P truncate textAlign="center" mt={2} mb={0}>
                  {title}
                </P>
              </Box>
            </Action>
          </Box>
        ))}
      </Box>
    </>
  );
};
