import { trackUser } from '@api/tracking/client';
import { CalloutCard } from '@mentimeter/ragnar-ui/callout-card';
import { Text } from '@mentimeter/ragnar-ui/text';

export function UserMenuUpgradeNotice() {
  return (
    <CalloutCard
      compact
      maxWidth={250}
      theme="positive"
      actions={[
        {
          children: 'Upgrade',
          variant: 'positive',
          key: 'upgrade',
          href: '/plans',
          target: '_blank',
          onClick: trackClickUpgrade,
        },
      ]}
    >
      <Text color="onPositiveWeakest" fontSize={1} mb={3}>
        Ready for unlimited participants, exporting results and much more?
      </Text>
    </CalloutCard>
  );
}

function trackClickUpgrade() {
  trackUser({
    event: 'Plans from homeview',
    properties: { context: 'User menu' },
  });
}
