import { useCallback, useMemo } from 'react';
import { useFeatures } from '@mentimeter/workspace-features';
import { getPages } from './pages';

export function usePageSearch() {
  const {
    hasWorkspacePresentationsPage,
    hideWorkspacePresentationPage,
    hasFindAvailableTeamsFeature,
  } = useFeatures();

  const pages = useMemo(
    () =>
      getPages({
        showWorkspacePresentations:
          hasWorkspacePresentationsPage && !hideWorkspacePresentationPage,
        showFindAvailableTeams: hasFindAvailableTeamsFeature,
      }),
    [
      hasFindAvailableTeamsFeature,
      hasWorkspacePresentationsPage,
      hideWorkspacePresentationPage,
    ],
  );

  const searchPages = useCallback(
    (searchString: string) => {
      const words = searchString.split(' ').filter((w) => w.length > 0);

      return pages.filter((page) =>
        page.keywords.some((keyword) =>
          words.some((word) => keyword.startsWith(word)),
        ),
      );
    },
    [pages],
  );

  return {
    searchPages,
  };
}
