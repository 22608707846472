import { TrackingContext, WorkspaceRoleEnum } from '@mentimeter/http-clients';
import { getNameInitials, useUser } from '@mentimeter/user';
import { useFeatures } from '@mentimeter/workspace-features';
import { logout } from 'lib/legacy-actions';
import { useState } from 'react';
import { useDevelopmentToolsModal } from '@mentimeter/development-tools/hooks';
import { trackUser } from '@api/tracking/client';
import { useSubscriptions } from '@mentimeter/billing';
import { Avatar } from '@mentimeter/ragnar-ui/avatar';
import { Badge } from '@mentimeter/ragnar-ui/badge';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';
import {
  DropdownMenuContainer,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from '@mentimeter/ragnar-ui/menu';
import { Text } from '@mentimeter/ragnar-ui/text';
import { getProfileMenuItems } from './userMenuItems';
import { UserMenuUpgradeNotice } from './UserMenuUpgradeNotice';
import { UserMenuItem } from './UserMenuItem';
import type { UserMenuItemT } from './UserMenuItem';

export function UserMenu() {
  const [open, setOpen] = useState(false);
  const { user } = useUser();
  const { subscriptions } = useSubscriptions(!user);
  const licenseAmount = subscriptions?.current_subscription?.licenses;

  const {
    hasWorkspaceInsightsFeature,
    hasGroupsFeature,
    hasFindAvailableTeamsFeature,
    featureAccessLevel,
    isLoadingFeatures,
    hasWorkspaceRequestsPage,
  } = useFeatures();

  const isLoading = isLoadingFeatures || !user;
  if (isLoading) return null;

  const isFree = user?.isFreeUser;
  const hasMultipleLicenses = licenseAmount && licenseAmount > 1;
  const profilePictureUrl = user.profile_picture?.presets.preview.url;
  const profileBadge =
    hasMultipleLicenses && user.ownerOrAdmin
      ? WorkspaceRoleEnum.ADMIN
      : undefined;

  const profileActions = getProfileMenuItems({
    featureAccessLevel,
    isOwnerOrAdmin: user.ownerOrAdmin,
    hasGroupsFeature,
    hasWorkspaceInsightsFeature,
    hasFindAvailableTeamsFeature,
    hasWorkspaceRequestsPage,
  });

  const bottomActions: UserMenuItemT[] = [
    {
      key: 'logout',
      children: 'Log out',
      ariaLabel: 'Log out',
      href: '/',
      variant: 'negative',
      onClick: (e) => {
        e.preventDefault();
        trackUser({ event: 'Logged out' });
        logout();
      },
    },
  ];

  return (
    <DropdownMenuRoot open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger>
        <Clickable
          aria-label="Open profile menu"
          id="profile-image-thumbnail"
          onClick={() => {
            trackUser({
              event: 'Clicked profile menu',
              properties: {
                context: TrackingContext.MyPresentations,
              },
            });
          }}
        >
          <Avatar
            itemId={String(user.id)}
            initials={getNameInitials(user.name).toUpperCase()}
            name={user.name}
            type="user"
            color="black"
            size="large"
            badge={profileBadge}
            profilePictureUrl={profilePictureUrl}
          />
        </Clickable>
      </DropdownMenuTrigger>
      <DropdownMenuContainer showArrow={false} align="end" sideOffset={12}>
        <>
          <UserInfoSection
            isFree={isFree}
            userName={user.name}
            userEmail={user.email}
            dismiss={dismiss}
          />

          {isFree && <UserMenuUpgradeNotice />}

          {profileActions.map((action, index) => (
            <UserMenuItem
              key={action.key}
              dismiss={dismiss}
              action={action}
              index={index}
            />
          ))}
          {bottomActions.map((action, index) => (
            <UserMenuItem
              key={action.key}
              dismiss={dismiss}
              action={action}
              index={index}
            />
          ))}
        </>
      </DropdownMenuContainer>
    </DropdownMenuRoot>
  );

  function dismiss() {
    setOpen(false);
  }
}

function InlineNewBadge() {
  return (
    <Box display="inline-block" ml="space2">
      <Badge
        bg="noticeWeak"
        color="onNoticeWeak"
        type="text"
        text="free"
        compact
      />
    </Box>
  );
}

function UserInfoSection({
  isFree,
  userName,
  userEmail,
  dismiss,
}: {
  isFree: boolean;
  userName: string;
  userEmail: string;
  dismiss: () => void;
}) {
  const { tryOpenDevelopmentToolsModal } = useDevelopmentToolsModal();

  return (
    <Box minWidth="220px" maxWidth="250px" px="space2" py="space2">
      {isFree ? (
        <Box width="100%" flexDirection="row" alignItems="center">
          <Text truncate flex="0 1 auto">
            {userName}
          </Text>
          <InlineNewBadge />
        </Box>
      ) : (
        <Text truncate>{userName}</Text>
      )}

      <Text
        color="textWeaker"
        fontSize={1}
        truncate
        onClick={() => {
          if (tryOpenDevelopmentToolsModal()) {
            dismiss();
          }
        }}
      >
        {userEmail}
      </Text>
    </Box>
  );
}
