import { useUser, type UserT } from '@mentimeter/user';
import {
  useWorkspace,
  type WorkspaceT,
} from '@mentimeter/workspace-data-hooks';

function parsedFirstName(nameString: string): string {
  const namesArray = nameString.split(' ');

  return namesArray.length > 1
    ? namesArray.slice(0, -1).join(' ')
    : (namesArray[0] as string);
}

const getWorkspaceName = (
  workspace: WorkspaceT | undefined,
  user: UserT | null,
) => {
  if (workspace?.name) return workspace.name;
  if (workspace?.ownerName)
    return `${parsedFirstName(workspace.ownerName)}'s team`;
  if (user?.name) return `${parsedFirstName(user.name)}'s team`;

  return 'Workspace';
};

const getWorkspacePresentationsFeatureTitle = (
  workspace: WorkspaceT | undefined,
  user: UserT | null,
) => {
  if (workspace?.name) return `${workspace.name} presentations`;
  if (workspace?.ownerName)
    return `${parsedFirstName(workspace.ownerName)}'s team`;
  if (user?.name) return `${parsedFirstName(user.name)}'s team`;

  return 'Workspace presentations';
};

export const useWorkspacePresentationsFeatureTitle = () => {
  const { data: workspace, isLoading: isLoadingWorkspace } = useWorkspace();
  const { user } = useUser();

  const workspacePresentationsFeatureTitle =
    getWorkspacePresentationsFeatureTitle(workspace, user);
  const workspaceName = getWorkspaceName(workspace, user);

  return {
    isLoading: isLoadingWorkspace || !user,
    workspacePresentationsFeatureTitle,
    workspaceName,
  };
};
