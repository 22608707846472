import { Text } from '@mentimeter/ragnar-ui/text';
import {
  getTimeAgo,
  NotificationBody,
  NotificationContainer,
  NotificationAvatar,
  NotificationLink,
  NotificationHeader,
} from '../../notification';
import { truncate } from '../../notification/utils/truncate';
import type { InvitedToResourceNotificationT } from './schema';

export const InvitedToResourceNotification = ({
  notification: {
    id,
    timestamp,
    payload: {
      resourceName,
      resourceType,
      inviteLink,
      senderProfilePictureUrl,
      senderName,
    },
    status,
  },
  onClick,
}: {
  notification: InvitedToResourceNotificationT;
  onClick: () => void;
}) => {
  const truncatedResourceName = truncate(resourceName);

  return (
    <NotificationLink href={inviteLink} onClick={onClick}>
      <NotificationContainer key={id}>
        <NotificationHeader>
          <NotificationAvatar
            name={senderName}
            profilePictureUrl={senderProfilePictureUrl}
          />
        </NotificationHeader>
        <NotificationBody status={status}>
          <Text>
            {resourceType === 'series' ? (
              <>
                <Text fontWeight="semiBold">{senderName}</Text> invited you to
                edit <Text fontWeight="semiBold">{truncatedResourceName}</Text>.
              </>
            ) : (
              <>
                <Text fontWeight="semiBold">{senderName}</Text> gave you access
                to the{' '}
                <Text fontWeight="semiBold">{truncatedResourceName}</Text>{' '}
                folder.
              </>
            )}
          </Text>
          <Text color="textWeaker">{getTimeAgo(timestamp)} ago</Text>
        </NotificationBody>
      </NotificationContainer>
    </NotificationLink>
  );
};
