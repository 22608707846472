import React from 'react';
import { Title } from '@radix-ui/react-dialog';
import type { DialogTitleProps } from '@radix-ui/react-dialog';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { ragnarTypography } from '@mentimeter/ragnar-typography';

export const DrawerTitle = React.forwardRef<
  HTMLHeadingElement,
  DialogTitleProps
>(({ className, ...rest }, forwardedRef) => (
  <Title
    ref={forwardedRef}
    className={cn('text', ragnarTypography.ragnarBodyLg, className)}
    {...rest}
  />
));
