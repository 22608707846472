import { Text } from '@mentimeter/ragnar-ui/text';
import {
  getTimeAgo,
  NotificationBody,
  NotificationContainer,
  NotificationAvatar,
  NotificationLink,
  NotificationHeader,
} from '../../notification';
import type { AssignedNewRoleNotificationT } from './schema';

const ROLE_NAMES: Record<
  AssignedNewRoleNotificationT['payload']['newRole'],
  string
> = {
  admin: 'admin',
  user: 'full member',
  member_lite: 'member lite',
};

const FROM_TO_MAP: Record<
  AssignedNewRoleNotificationT['payload']['previousRole'],
  Record<AssignedNewRoleNotificationT['payload']['newRole'], string | null>
> = {
  admin: {
    member_lite: 'downgraded you to',
    user: 'changed your role from admin to',
    admin: null,
  },
  user: {
    member_lite: 'downgraded you to',
    user: null,
    admin: 'changed your role to an',
  },
  member_lite: {
    member_lite: null,
    user: 'upgraded you to a',
    admin: 'changed your role to an',
  },
};
const FALLBACK_MESSAGE = 'changed your role to';

export const AssignedNewRoleNotification = ({
  notification: {
    id,
    timestamp,
    payload: { senderProfilePictureUrl, senderName, previousRole, newRole },
    status,
  },
  onClick,
}: {
  notification: AssignedNewRoleNotificationT;
  onClick: () => void;
}) => {
  const message = getMessage(previousRole, newRole);

  return (
    <NotificationLink href="/app/manage-members" onClick={onClick}>
      <NotificationContainer key={id}>
        <NotificationHeader>
          <NotificationAvatar
            name={senderName}
            profilePictureUrl={senderProfilePictureUrl}
          />
        </NotificationHeader>
        <NotificationBody status={status}>
          <Text>
            <>
              <Text fontWeight="semiBold">{senderName}</Text> {message}{' '}
              <Text fontWeight="semiBold">{ROLE_NAMES[newRole]}</Text>.
            </>
          </Text>
          <Text color="textWeaker">{getTimeAgo(timestamp)} ago</Text>
        </NotificationBody>
      </NotificationContainer>
    </NotificationLink>
  );
};

function getMessage(
  previousRole: AssignedNewRoleNotificationT['payload']['previousRole'],
  newRole: AssignedNewRoleNotificationT['payload']['newRole'],
) {
  return FROM_TO_MAP[previousRole][newRole] ?? FALLBACK_MESSAGE;
}
