'use client';

import { isSafeUrl } from '@mentimeter/auth';
import DOMPurify from 'dompurify';

const ALLOWED_HOSTNAMES = [
  'mentimeter.com',
  'www.mentimeter.com',
  'mentimeter.dev',
  'www.mentimeter.dev',
  'stage-mentimeter.com',
  'www.stage-mentimeter.com',
  'menti.com',
  'www.menti.com',
  'menti.dev',
  'www.menti.dev',
  'stage-menti.com',
  'www.stage-menti.com',
];

const purifier = DOMPurify();

export function sanitizeUrl(url: string, defaultUrl: string) {
  try {
    if (purifier.isValidAttribute('a', 'href', url)) {
      if (isSafeUrl(url)) return url;

      const hostname = new URL(url).hostname;
      if (ALLOWED_HOSTNAMES.includes(hostname)) {
        return url;
      }
    }
  } catch (_) {}

  return defaultUrl;
}
