import { LoaderIcon } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui/box';
import { NotificationCenterHeading } from './NotificationCenterHeading';

export const NotificationCenterLoadingState = () => {
  return (
    <>
      <NotificationCenterHeading />
      <Box justifyContent="center" alignItems="center" p="space6" mb="space3">
        <LoaderIcon />
      </Box>
    </>
  );
};
