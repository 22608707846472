import type { ReactNode } from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { ScreenReaderOnly } from '@mentimeter/ragnar-ui/screenreader-only';

const DESCRIPTION_BY_STATUS = {
  seen: 'New notification:',
  sent: 'New notification:',
  read: 'Already read notification:',
};

export const NotificationBody = ({
  status,
  children,
}: {
  status: 'sent' | 'seen' | 'read';
  children: ReactNode;
}) => {
  return (
    <Box flex="1 1 auto" gap="space0.5" data-testid="notification-body">
      <ScreenReaderOnly>{DESCRIPTION_BY_STATUS[status]}</ScreenReaderOnly>
      {children}
    </Box>
  );
};
