import type { ReactNode } from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';

export const NotificationContainer = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <Box
      gap="space4"
      alignItems="stretch"
      flexDirection="row"
      p="space4"
      borderRadius="xl"
      extend={({ theme }) => ({
        ':hover': {
          backgroundColor: theme.colors.surfaceHover,
        },
      })}
    >
      {children}
    </Box>
  );
};
