import type { ReactNode } from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';

export const NotificationCenterList = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <Box mb="space2" width="100%" alignItems="stretch">
      {children}
    </Box>
  );
};
