import {
  UserFeaturesTypeEnum,
  type UserFeaturesType,
} from '@mentimeter/http-clients';
import type { UserMenuItemT } from './UserMenuItem';

export const SETTINGS = {
  children: 'Account settings',
  href: '/app/settings',
  key: 'account-settings',
  ariaLabel: 'Account settings',
};
export const FIND_AVAILABLE_TEAMS = {
  key: 'Find available teams',
  children: 'Find workspace',
  href: '/app/find-your-team',
  ariaLabel: 'Find workspace',
};
export const BILLING = {
  key: 'Billing',
  children: 'Billing',
  href: '/app/billing',
  ariaLabel: 'Billing',
};
export const WORKSPACE_INSIGHTS = {
  key: 'WorkspaceInsights',
  children: 'Workspace insights',
  href: '/app/workspace-insights',
  ariaLabel: 'Workspace insights',
};
export const GROUPS = {
  key: 'Groups',
  children: 'Groups',
  href: '/app/groups',
  ariaLabel: 'Groups',
};
export const REQUESTS = {
  key: 'Requests',
  children: 'Requests',
  href: '/app/requests',
  ariaLabel: 'Requests',
  dataTestId: 'requests-menu-item',
};

export function getProfileMenuItems({
  featureAccessLevel,
  isOwnerOrAdmin,
  hasGroupsFeature,
  hasWorkspaceInsightsFeature,
  hasFindAvailableTeamsFeature,
  hasWorkspaceRequestsPage,
}: {
  featureAccessLevel: UserFeaturesType;
  hasGroupsFeature: boolean;
  isOwnerOrAdmin: boolean;
  hasWorkspaceInsightsFeature: boolean;
  hasFindAvailableTeamsFeature: boolean;
  hasWorkspaceRequestsPage: boolean | undefined;
}): UserMenuItemT[] {
  const workspaceMenuItems = getWorkspaceMenuItems({
    isOwnerOrAdmin,
  });
  let links = [];
  switch (featureAccessLevel) {
    case UserFeaturesTypeEnum.REGISTERED:
      links = [SETTINGS, BILLING, ...workspaceMenuItems];
      if (hasFindAvailableTeamsFeature) links.push(FIND_AVAILABLE_TEAMS);

      return links;

    case UserFeaturesTypeEnum.BASIC:
    case UserFeaturesTypeEnum.PRO:
    case UserFeaturesTypeEnum.ENTERPRISE:
    case UserFeaturesTypeEnum.ENTERPRISE_TRIAL:
      if (isOwnerOrAdmin) {
        links = [SETTINGS, BILLING, ...workspaceMenuItems];
      } else {
        links = [SETTINGS, ...workspaceMenuItems];
      }
      if (hasWorkspaceRequestsPage) {
        links.push(REQUESTS);
      }
      if (hasGroupsFeature) links.push(GROUPS);
      if (hasWorkspaceInsightsFeature) links.push(WORKSPACE_INSIGHTS);
      if (hasFindAvailableTeamsFeature) links.push(FIND_AVAILABLE_TEAMS);

      return links;

    default:
      return [];
  }
}

function getWorkspaceMenuItems({
  isOwnerOrAdmin,
}: {
  isOwnerOrAdmin: boolean;
}) {
  const workspaceSettings = {
    key: 'Workspace settings',
    children: 'Workspace settings',
    href: '/app/workspace-settings',
    ariaLabel: 'Workspace settings',
  };
  const manageMembers = {
    key: 'Manage members',
    children: 'Manage members',
    href: '/app/manage-members',
    ariaLabel: 'Manage members',
  };
  return isOwnerOrAdmin ? [workspaceSettings, manageMembers] : [manageMembers];
}
