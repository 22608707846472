import { getNameInitials } from '@mentimeter/user';
import { Avatar } from '@mentimeter/ragnar-ui/avatar';

export function NotificationAvatar({
  profilePictureUrl,
  name,
  testId,
}: {
  profilePictureUrl: string | null | undefined;
  name: string;
  testId?: string;
}) {
  return (
    <Avatar
      size="large"
      data-testid={testId}
      initials={getNameInitials(name).toUpperCase()}
      name={name}
      itemId={name}
      type="user"
      aria-hidden
      profilePictureUrl={profilePictureUrl ?? null}
    />
  );
}
