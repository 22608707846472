import { useOnce } from '@mentimeter/react-hooks';
import type { TrackingContext } from '@mentimeter/http-clients';
import { trackUser } from '@api/tracking/client';
import { Box } from '@mentimeter/ragnar-ui/box';
import { NotificationIndicator } from '../notification';
import { useMarkAllNotifications } from '../hooks/useMarkAllNotifications';
import { useNotifications } from '../hooks/useNotifications';
import { NotificationRenderer } from '../notifications/NotificationRenderer';
import type { NotificationT } from '../notifications/types';
import {
  GenericNotification,
  genericSchema,
  type GenericNotificationT,
} from '../notifications/generic';
import { useMarkNotificationAsRead } from '../hooks/useMarkNotificationAsRead';
import {
  NotificationCenterEmptyState,
  NotificationCenterErrorState,
  NotificationCenterHeading,
  NotificationCenterList,
  NotificationCenterLoadingState,
  NotificationCenterPopover,
  NotificationCenterTurnedOffState,
} from './components';

const UNSEEN_NOTIFICATION_STATUSES = ['sent'];
const UNREAD_NOTIFICATION_STATUSES = ['sent', 'seen'];

export function NotificationCenterDataHandler({
  trackingContext,
}: {
  trackingContext: TrackingContext | undefined;
}) {
  const { notifications, isLoading, error, notificationsEnabled } =
    useNotifications();
  const markAllAsSeen = useMarkAllNotifications('seen');
  const markAllAsRead = useMarkAllNotifications('read');
  const markNotificationAsRead = useMarkNotificationAsRead();

  const hasNotifications = !!notifications && notifications.length > 0;
  const unseenNotifications =
    notifications?.filter((notification) =>
      UNSEEN_NOTIFICATION_STATUSES.includes(notification.status),
    ) ?? [];
  const hasUnseenNotifications = unseenNotifications.length > 0;

  const unreadNotifications =
    notifications?.filter((notification) =>
      UNREAD_NOTIFICATION_STATUSES.includes(notification.status),
    ) ?? [];

  useOnce(!isLoading, trackViewNotifications);

  const viewState = getViewState({
    hasError: !!error,
    isLoading,
    hasNotifications,
    notificationsEnabled,
  });

  return (
    <NotificationCenterPopover
      showIndicator={hasUnseenNotifications}
      onClick={onClickNotificationBell}
    >
      {viewState === 'disabled' && <NotificationCenterTurnedOffState />}
      {viewState === 'error' && <NotificationCenterErrorState />}
      {viewState === 'loading' && <NotificationCenterLoadingState />}
      {viewState === 'empty' && <NotificationCenterEmptyState />}
      {viewState === 'list' && (
        <>
          <NotificationCenterHeading onClick={markAllAsRead} />
          <NotificationCenterList>
            {notifications?.map((notification) => {
              return (
                <Box key={notification.id} flexDirection="row">
                  {isGenericNotification(notification) ? (
                    <GenericNotification
                      notification={notification}
                      onClick={() => onClickNotification(notification)}
                    />
                  ) : (
                    <NotificationRenderer
                      notification={notification}
                      onClick={() => onClickNotification(notification)}
                    />
                  )}
                  {notification.status !== 'read' && (
                    <NotificationIndicator left={13} top={0} />
                  )}
                </Box>
              );
            })}
          </NotificationCenterList>
        </>
      )}
    </NotificationCenterPopover>
  );

  function onClickNotificationBell() {
    markAllAsSeen();
    trackOpenNotifications();
  }

  function onClickNotification(
    notification: NotificationT | GenericNotificationT,
  ) {
    markNotificationAsRead({ notificationId: notification.id });
    trackClickNotification(notification);
  }

  function trackViewNotifications() {
    trackUser({
      event: 'Viewed notification bell',
      properties: {
        context: trackingContext,
        'notification count': notifications?.length ?? 0,
        'unseen notification count': unseenNotifications.length,
        'unread notification count': unreadNotifications.length,
      },
    });
  }

  function trackOpenNotifications() {
    trackUser({
      event: 'Clicked notification bell',
      properties: {
        context: trackingContext,
        'notification count': notifications?.length ?? 0,
        'unseen notification count': unseenNotifications.length,
        'unread notification count': unreadNotifications.length,
      },
    });
  }

  function trackClickNotification(
    notification: NotificationT | GenericNotificationT,
  ) {
    trackUser({
      event: 'Clicked notification',
      properties: {
        context: trackingContext,
        'notification type': notification.type,
        'notification sent at': notification.timestamp,
      },
    });
  }
}

function getViewState({
  hasError,
  isLoading,
  hasNotifications,
  notificationsEnabled,
}: {
  hasError: boolean;
  isLoading: boolean;
  hasNotifications: boolean;
  notificationsEnabled: boolean;
}): 'disabled' | 'error' | 'empty' | 'list' | 'loading' {
  if (!notificationsEnabled) return 'disabled';
  if (hasError) return 'error';
  if (isLoading) return 'loading';
  if (!hasNotifications) return 'empty';
  return 'list';
}

function isGenericNotification(
  notification: NotificationT | GenericNotificationT,
): notification is GenericNotificationT {
  return genericSchema.safeParse(notification).success;
}
