import { ErrorBoundary } from '@mentimeter/errors/ErrorBoundary';
import type { TrackingContext } from '@mentimeter/http-clients';
import { NotificationCenterDataHandler } from './NotificationCenterDataHandler';
import {
  NotificationCenterErrorState,
  NotificationCenterPopover,
} from './components';

export function NotificationCenter({
  trackingContext,
}: {
  trackingContext: TrackingContext | undefined;
}) {
  return (
    <ErrorBoundary
      feature="notifications"
      fallback={
        // Show a dummy popover with an error state
        <NotificationCenterPopover showIndicator={false} onClick={() => {}}>
          <NotificationCenterErrorState />
        </NotificationCenterPopover>
      }
    >
      <NotificationCenterDataHandler trackingContext={trackingContext} />
    </ErrorBoundary>
  );
}
