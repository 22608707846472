import { Text } from '@mentimeter/ragnar-ui/text';
import {
  getTimeAgo,
  NotificationBody,
  NotificationContainer,
  NotificationAvatar,
  NotificationLink,
  NotificationHeader,
} from '../../notification';
import { truncate } from '../../notification/utils/truncate';
import type { ResolvedCommentNotificationT } from './schema';

export function ResolvedCommentNotification({
  notification: {
    id,
    timestamp,
    payload: {
      senderName,
      senderEmail,
      senderProfilePictureUrl,
      seriesName,
      commentLink,
    },
    status,
  },
  onClick,
}: {
  notification: ResolvedCommentNotificationT;
  onClick: () => void;
}) {
  const senderDisplayName = senderName || senderEmail;
  const truncatedResourceName = truncate(seriesName);

  return (
    <NotificationLink href={commentLink} onClick={onClick}>
      <NotificationContainer key={id}>
        <NotificationHeader>
          <NotificationAvatar
            name={senderDisplayName}
            profilePictureUrl={senderProfilePictureUrl}
          />
        </NotificationHeader>
        <NotificationBody status={status}>
          <Text>
            <Text fontWeight="semiBold">{senderDisplayName}</Text> resolved your
            comment on{' '}
            <Text fontWeight="semiBold">{truncatedResourceName}</Text>.
          </Text>
          <Text color="textWeaker">{getTimeAgo(timestamp)} ago</Text>
        </NotificationBody>
      </NotificationContainer>
    </NotificationLink>
  );
}
