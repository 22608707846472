import type { FolderId } from '@mentimeter/http-clients';
import {
  useParams,
  usePathname,
  useSearchParams,
} from '@mentimeter/next-navigation';
import {
  ROOT_FOLDER_ID,
  isSortKey,
  SORT_KEYS,
  isSortOrder,
  DEFAULT_SORT_BY_ORDER,
  DASHBOARD_TYPE,
} from 'features/dashboard/presentation-view/constants';
import { DashboardTypeEnum } from './presentation-view/types';

export const DASHBOARD_PATHS = {
  private: 'dashboard',
  workspace: 'workspace',
  sharedWithMe: 'shared-with-me',
};

export function createSearchURL(
  searchQuery: string,
  dashboardType: DashboardTypeEnum,
  otherParams: URLSearchParams,
  currentFolderId?: FolderId,
) {
  // Append search query param when searching for an item
  const searchParams = new URLSearchParams({
    ...Object.fromEntries(otherParams.entries()),
    search: searchQuery,
  }).toString();
  const queryString = searchParams ? `?${searchParams}` : '';

  return createDashboardUrl(currentFolderId, dashboardType, queryString);
}

export const createFolderURL = (
  currentFolderId: FolderId | string = ROOT_FOLDER_ID,
  dashboardType: DashboardTypeEnum,
  currentSearchParams: URLSearchParams,
) => {
  // Remove search query param when redirecting to a folder
  const searchParams = new URLSearchParams(
    Object.fromEntries(
      Array.from(currentSearchParams.entries()).filter(
        ([key]) => key !== 'search',
      ),
    ),
  ).toString();

  const queryString = searchParams ? `?${searchParams}` : '';

  return createDashboardUrl(currentFolderId, dashboardType, queryString);
};

const createDashboardUrl = (
  currentFolderId: FolderId | string = ROOT_FOLDER_ID,
  dashboardType: DashboardTypeEnum,
  searchParams: string,
) => {
  const dashboardPath = DASHBOARD_PATHS[dashboardType];

  return currentFolderId === ROOT_FOLDER_ID
    ? `/app/${dashboardPath}${searchParams}`
    : dashboardType === DASHBOARD_TYPE.PRIVATE
      ? `/app/folder/${currentFolderId}${searchParams}`
      : `/app/${dashboardPath}/folder/${currentFolderId}${searchParams}`;
};

const refineQueryType = (
  query: string | string[] | undefined | null,
): string | undefined => {
  if (Array.isArray(query)) {
    throw new Error('params are an array, expected string');
  }
  return query ?? undefined;
};

export const getDashboardTypeFromPath: (pathname: string) => DashboardTypeEnum =
  (pathname) => {
    if (pathname.includes(DASHBOARD_PATHS.workspace)) {
      return DashboardTypeEnum.WORKSPACE;
    }

    if (
      pathname.includes(DASHBOARD_PATHS.sharedWithMe) ||
      pathname.includes(DashboardTypeEnum.SHARED_WITH_ME)
    ) {
      return DashboardTypeEnum.SHARED_WITH_ME;
    }

    return DashboardTypeEnum.PRIVATE;
  };

export function useDashboardHistory() {
  const searchParams = useSearchParams();
  const folderId = useParams<{ folderId: string }>()?.folderId;
  const pathname = usePathname();

  const searchQuery = refineQueryType(searchParams?.get('search'));
  const isWorkspaceDashboard = pathname.includes(DASHBOARD_PATHS.workspace);
  const dashboardType = getDashboardTypeFromPath(pathname);
  const isSharedWithMePage = pathname.includes(DASHBOARD_PATHS.sharedWithMe);

  const sortByRaw = refineQueryType(searchParams?.get('sort_by'));
  const sortBy = isSortKey(sortByRaw) ? sortByRaw : SORT_KEYS.updatedAt;

  const sortOrderRaw = refineQueryType(searchParams?.get('sort_order'));
  const sortOrder = isSortOrder(sortOrderRaw)
    ? sortOrderRaw
    : DEFAULT_SORT_BY_ORDER[sortBy];

  const currentFolderId =
    folderId === undefined ? ROOT_FOLDER_ID : parseInt(folderId, 10);

  const isRootFolder = currentFolderId === ROOT_FOLDER_ID;
  const currentLocationId = folderId || dashboardType;

  return {
    currentFolderId,
    searchQuery,
    sortBy,
    sortOrder,
    isWorkspaceDashboard,
    isSharedWithMePage,
    isRootFolder,
    dashboardType,
    currentLocationId,
  };
}
