import { type IRenderer, useRagnar } from '@mentimeter/ragnar-react';
import type { BoxT } from '@mentimeter/ragnar-ui/box';
import { Box } from '@mentimeter/ragnar-ui/box';

const animationStyle = (renderer: IRenderer) => {
  const blinkKeyframe =
    renderer.renderKeyframe &&
    renderer.renderKeyframe(
      () => ({
        '25%': { transform: 'scale(1.3)' },
        '50%': { transform: 'scale(0.7)' },
        '75%': { transform: 'scale(1.2)' },
        '80%': { transform: 'scale(0.8)' },
        '90%': { transform: 'scale(1.1)' },
        '95%': { transform: 'scale(0.9)' },
        '100%': { transform: 'scale(1)' },
      }),
      {},
    );

  return {
    animationDuration: '1s',
    animationIterationCount: 1,
    animationName: blinkKeyframe,
  };
};

export const WigglingDot = ({
  refId,
  size = '13px',
  wiggle,
  bg,
  borderColor,
}: {
  refId: string;
  size?: string;
  wiggle: boolean;
  bg: BoxT['bg'];
  borderColor: BoxT['borderColor'];
}) => {
  const { renderer } = useRagnar();

  return (
    <Box
      id={refId}
      height={size}
      width={size}
      borderStyle="solid"
      borderColor={borderColor}
      borderWidth={3}
      borderRadius="full"
      bg={bg}
      style={wiggle ? animationStyle(renderer) : {}}
    />
  );
};

export const UrgentDot = ({ id, size }: { id: string; size: string }) => {
  return (
    <WigglingDot
      refId={id}
      wiggle
      bg="negative"
      borderColor="surface"
      size={size}
    />
  );
};
