import { MenuIcon } from '@mentimeter/ragnar-visuals';
import { DrawerRoot, DrawerTrigger } from '@mentimeter/ragnar-ui/drawer';
import {
  IconButton,
  type IconButtonProps,
} from '@mentimeter/ragnar-ui/icon-button';
import MobileMenu from '../../../mobile-menu/mobile-menu';

export function ToggleMobileMenuButton({ ...props }: {} & IconButtonProps) {
  return (
    <DrawerRoot>
      <DrawerTrigger>
        <IconButton
          size="large"
          variant="secondary"
          alignItems="flex-end"
          justifyContent="center"
          {...props}
        >
          <MenuIcon color="inherit" />
        </IconButton>
      </DrawerTrigger>
      <MobileMenu />
    </DrawerRoot>
  );
}
