import React from 'react';
import type { PlanCategory, TrackingContext } from '@mentimeter/http-clients';
import { trackClickUpgrade } from '@mentimeter/dashboard-tracking';
import { useUser, getIsMemberLite } from '@mentimeter/user';
import { StarSolidIcon } from '@mentimeter/ragnar-visuals';
import { billingRules, useSubscriptions } from '@mentimeter/billing';
import { useShouldShowEngagementLimitBanner } from '../engagement-limit';
import {
  RequestUpgradeButton,
  RequestUpgradeModal,
} from '../lib/request-upgrade';
import { UpgradeButton } from './UpgradeButton';

export function UserActionsUpgradeButton({
  trackingContext,
}: {
  trackingContext: TrackingContext | undefined;
}) {
  const { user } = useUser();
  const isFreeUser = user?.isFreeUser;
  const { subscriptions } = useSubscriptions();
  const { planCategory } = billingRules(subscriptions);
  const bannerWithUpgradeButtonVisible = useShouldShowEngagementLimitBanner();
  const isMemberLite = getIsMemberLite(user);

  if (
    !user ||
    bannerWithUpgradeButtonVisible ||
    !canSelfUpgrade(planCategory, isFreeUser || false)
  )
    return null;

  const isBasic = planCategory?.includes('basic');
  const plan = isBasic ? 'Basic' : 'Free';

  if (isMemberLite) {
    return (
      <RequestUpgradeModal
        trackingContext={trackingContext}
        trackingPlacement="Header user actions"
      >
        <RequestUpgradeButton variant="outline" iconLeading={StarSolidIcon} />
      </RequestUpgradeModal>
    );
  } else {
    return (
      <UpgradeButton
        variant="positive"
        iconLeading={StarSolidIcon}
        onClick={() => {
          trackClickUpgrade({
            event: 'Plans from homeview',
            properties: {
              context: 'Top header',
              plan: plan ? plan : null,
            },
          });
        }}
      />
    );
  }
}

function canSelfUpgrade(
  planCategory: PlanCategory | undefined,
  isFreeUser: boolean,
) {
  return (
    planCategory === 'free' ||
    isFreeUser ||
    planCategory === 'pro-trial' ||
    planCategory?.includes('basic')
  );
}
