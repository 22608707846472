import type React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';

export function NotificationIcon({
  icon,
  testId,
}: { icon: React.ReactNode; testId?: string }) {
  return (
    <Box
      data-testid={testId}
      width={40}
      height={40}
      borderRadius="full"
      bg="infoWeak"
      alignItems="center"
      justifyContent="center"
    >
      {icon}
    </Box>
  );
}
