import { Box } from '@mentimeter/ragnar-ui/box';
import { UrgentDot } from './WigglingDotBadge';

export function NotificationIndicator({
  left,
  right,
  top,
}: {
  left?: number;
  right?: number;
  top: number;
}) {
  return (
    <Box mt="space4" position="absolute" left={left} right={right} top={top}>
      <UrgentDot id="notification-center-item-indicator" size="14px" />
    </Box>
  );
}
