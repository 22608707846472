import { useWorkspace } from '@mentimeter/workspace-data-hooks';
import { Text } from '@mentimeter/ragnar-ui/text';
import {
  getTimeAgo,
  NotificationBody,
  NotificationContainer,
  NotificationAvatar,
  NotificationLink,
  NotificationHeader,
} from '../../notification';
import { WorkspaceName } from '../../notification/components/WorkspaceName';
import type { RequestedToJoinWorkspaceNotificationT } from './schema';

export const RequestedToJoinWorkspaceNotification = ({
  notification: {
    id,
    timestamp,
    payload: { senderName, senderProfilePictureUrl },
    status,
  },
  onClick,
}: {
  notification: RequestedToJoinWorkspaceNotificationT;
  onClick: () => void;
}) => {
  const { data: workspace } = useWorkspace();

  return (
    <NotificationLink href="/app/requests" onClick={onClick}>
      <NotificationContainer key={id}>
        <NotificationHeader>
          <NotificationAvatar
            name={senderName}
            profilePictureUrl={senderProfilePictureUrl}
          />
        </NotificationHeader>
        <NotificationBody status={status}>
          <Text>
            <Text fontWeight="semiBold">{senderName}</Text> requested to join{' '}
            <WorkspaceName name={workspace?.name} />.
          </Text>
          <Text color="textWeaker">{getTimeAgo(timestamp)} ago</Text>
        </NotificationBody>
      </NotificationContainer>
    </NotificationLink>
  );
};
