import { cn } from '@mentimeter/ragnar-tailwind-config';
import { Overlay, type DialogOverlayProps } from '@radix-ui/react-dialog';
import React from 'react';

export const DrawerOverlay = React.forwardRef<
  HTMLDivElement,
  DialogOverlayProps
>(({ className, ...rest }, forwardedRef) => (
  <Overlay
    ref={forwardedRef}
    className={cn(
      [
        'bg-overlay',
        'top-0',
        'right-0',
        'bottom-0',
        'left-0',
        'fixed',
        'z-modal',
        'animate-fade-in',
        'data-[state=closed]:animate-fade-out',
      ],
      className,
    )}
    {...rest}
  />
));
