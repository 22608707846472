/* eslint-disable menti-react/filename-convention--jsx */
'use client';

import React, { useState } from 'react';
import {
  ChevronDownIcon,
  CrossIcon,
  HomeIcon,
  AdminIcon,
  InboxIcon,
  SettingsIcon,
  UserIcon,
  UsersIcon,
  TemplatePlusIcon,
  type FunctionalIconT,
  SendIcon,
} from '@mentimeter/ragnar-visuals';
import { Device } from '@mentimeter/ragnar-device';
import { LinkClickable, usePathname } from '@mentimeter/next-navigation';
import { useDevelopmentToolsModal } from '@mentimeter/development-tools/hooks';
import { logout } from 'lib/legacy-actions';
import { getIsMemberLite, useUser, getNameInitials } from '@mentimeter/user';
import { useFeatures } from '@mentimeter/workspace-features';
import { TemplateModal } from '@mentimeter/templates-modal/TemplateModal/TemplateModal';
import { useWorkspacePresentationsFeatureTitle } from '@mentimeter/workspace';
import { billingRules, useSubscriptions } from '@mentimeter/billing';
import {
  DrawerContainer,
  DrawerTitle,
  DrawerBody,
  DrawerDismiss,
} from '@mentimeter/ragnar-ui/drawer';
import { ScreenReaderOnly } from '@mentimeter/ragnar-ui/screenreader-only';
import { Box, type BoxT } from '@mentimeter/ragnar-ui/box';
import { Clickable, type ClickableT } from '@mentimeter/ragnar-ui/clickable';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Text } from '@mentimeter/ragnar-ui/text';
import { ModalRoot } from '@mentimeter/ragnar-ui/modal';
import { Collapsable } from '@mentimeter/ragnar-ui/collapsable';
import { IconButton } from '@mentimeter/ragnar-ui/icon-button';
import { Avatar } from '@mentimeter/ragnar-ui/avatar';
import { REQUESTS } from '../header/user-actions/components/user-menu/userMenuItems';
import { HelpModal } from '../header/user-actions/components/HelpModal';

/**
 * Menu components
 */

const MenuDrawer = ({
  children,
}: {
  children: React.ReactNode;
} & BoxT) => (
  <DrawerContainer side="right" className="p-0 overflow-auto">
    <ScreenReaderOnly>
      <DrawerTitle>User menu</DrawerTitle>
    </ScreenReaderOnly>
    <DrawerDismiss>
      <IconButton
        className="absolute top-1.5 right-1.5 z-modal"
        variant="secondary"
        aria-label="Close menu"
        size="large"
        borderRadius="full"
      >
        <CrossIcon />
      </IconButton>
    </DrawerDismiss>

    <DrawerBody className="pt-12 pb-8 px-8 overflow-visible">
      {children}
    </DrawerBody>
  </DrawerContainer>
);

const MenuSection = ({ children, ...rest }: BoxT) => (
  <Box width="100%" {...rest}>
    {children}
  </Box>
);

const MenuSectionHeading = ({ children }: { children: string }) => (
  <Box width="100%" px="space4" pt="space2" pb="space1">
    <Text fontSize="87.5" color="textWeaker">
      {children}
    </Text>
  </Box>
);

const MenuItem = ({
  children,
  active,
  ...rest
}: ClickableT & { active?: boolean }) => (
  <Clickable
    width="100%"
    borderRadius="xl"
    extend={({ theme }) => ({
      '@media (hover: hover)': {
        ':hover': {
          opacity: 1,
          backgroundColor: theme.colors.infoWeakest,
        },
      },
      ':active': {
        opacity: 1,
        backgroundColor: theme.colors.infoWeak,
      },
    })}
    {...rest}
  >
    {active && (
      <Box
        width="2px"
        bg="secondary"
        height="45%"
        position="absolute"
        top="50%"
        left={0}
        extend={() => ({
          transform: 'translateY(-50%)',
        })}
      />
    )}
    {children}
  </Clickable>
);

const MenuItemContent = ({
  children,
  compact = false,
  icon: Icon,
  ...props
}: BoxT & {
  compact?: boolean;
  icon?: React.ComponentType<FunctionalIconT> | undefined;
}) => (
  <Box
    alignItems="center"
    px="space4"
    py={compact ? 'space2' : 'space3'}
    flexDirection="row"
    gap="space3"
    width="100%"
    borderRadius="xl"
    {...props}
  >
    {Icon && (
      <Box>
        <Icon size={4} />
      </Box>
    )}
    <Text
      truncate
      lineHeight={compact ? 'normal' : 'snug'}
      fontSize={compact ? '100' : '125'}
      color="text"
    >
      {children}
    </Text>
  </Box>
);

const MenuItemNextNavigation = ({
  href,
  children,
  target = undefined,
  compact = false,
  icon,
  ...props
}: {
  href: string;
  children: React.ReactNode;
  target?: '_blank' | undefined;
  compact?: boolean;
  icon?: React.ComponentType<FunctionalIconT>;
}) => {
  const highlight = usePathname() === href;

  return (
    <MenuItem tabIndex={-1} active={highlight}>
      <DrawerDismiss>
        <LinkClickable
          {...props}
          href={href}
          target={target}
          width="100%"
          aria-label={`Navigate to ${children}`}
          extend={() => ({
            '@media (hover: hover)': {
              ':hover': {
                opacity: 1,
              },
            },
            ':active': {
              opacity: 1,
            },
          })}
        >
          <MenuItemContent icon={icon} compact={compact}>
            {children}
          </MenuItemContent>
        </LinkClickable>
      </DrawerDismiss>
    </MenuItem>
  );
};

const MenuCollapsable = ({
  children,
  title,
  'aria-label': ariaLabel,
  ...props
}: ClickableT) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <MenuItem
        {...props}
        aria-label={ariaLabel || 'Open drop down'}
        onClick={() => setShow((prev) => !prev)}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <MenuItemContent width="auto" icon={SettingsIcon}>
          {/* eslint-disable-next-line menti-react-jsx/no-possibly-undefined-in-jsx */}
          {title}
        </MenuItemContent>

        <Box
          mr="space4"
          extend={({ theme }) => ({
            transition: `transform ${theme.durations[1]}s ease`,
            transform: show ? 'rotate(180deg)' : 'rotate(0)',
          })}
        >
          <ChevronDownIcon size={4} />
        </Box>
      </MenuItem>

      <Collapsable width="100%" show={show} pl="space10">
        {children}
      </Collapsable>
    </>
  );
};

/**
 * All menu items
 */

const Home = () => (
  <MenuItemNextNavigation href="/app/home" icon={HomeIcon}>
    Home
  </MenuItemNextNavigation>
);

const MyPresentations = () => (
  <MenuItemNextNavigation icon={UserIcon} href="/app/dashboard">
    My presentations
  </MenuItemNextNavigation>
);

const FindAvailableTeams = () => {
  const { hasFindAvailableTeamsFeature } = useFeatures();
  if (!hasFindAvailableTeamsFeature) return null;

  return (
    <MenuItemNextNavigation icon={SendIcon} href="/app/find-your-team">
      Find workspace
    </MenuItemNextNavigation>
  );
};

const SharedWithMe = () => (
  <MenuItemNextNavigation icon={InboxIcon} href="/app/shared-with-me">
    Shared with me
  </MenuItemNextNavigation>
);

const Billing = () => (
  <MenuItemNextNavigation compact href="/app/billing">
    Billing
  </MenuItemNextNavigation>
);

const ManageMembers = () => (
  <MenuItemNextNavigation compact href="/app/manage-members">
    Manage members
  </MenuItemNextNavigation>
);

const WorkspaceSettings = () => {
  const { hasWorkspaceSettingsFeature } = useFeatures();
  if (!hasWorkspaceSettingsFeature) return null;

  return (
    <MenuItemNextNavigation compact href="/app/workspace-settings">
      Workspace settings
    </MenuItemNextNavigation>
  );
};

const Groups = () => {
  const { hasGroupsFeature } = useFeatures();
  if (!hasGroupsFeature) return null;

  return (
    <MenuItemNextNavigation compact href="/app/groups">
      Groups
    </MenuItemNextNavigation>
  );
};

const WorkspaceInsights = () => {
  const { hasWorkspaceInsightsFeature } = useFeatures();
  if (!hasWorkspaceInsightsFeature) return null;

  return (
    <MenuItemNextNavigation compact href="/app/workspace-insights">
      Workspace insights
    </MenuItemNextNavigation>
  );
};

function Requests() {
  const { hasWorkspaceRequestsPage } = useFeatures();

  if (hasWorkspaceRequestsPage) {
    return (
      <MenuItemNextNavigation compact href={REQUESTS.href}>
        {REQUESTS.key}
      </MenuItemNextNavigation>
    );
  }

  return null;
}

const MyProfile = () => (
  <MenuItemNextNavigation icon={AdminIcon} href="/app/settings">
    My profile
  </MenuItemNextNavigation>
);

const Workspace = ({ children }: { children: string }) => (
  <MenuItemNextNavigation icon={UsersIcon} href="/app/workspace">
    {children}
  </MenuItemNextNavigation>
);

const SharedTemplates = () => (
  <MenuItemNextNavigation icon={TemplatePlusIcon} href="/app/shared">
    Shared templates
  </MenuItemNextNavigation>
);

const Plans = () => (
  <MenuItemNextNavigation compact href="/plans">
    Plans
  </MenuItemNextNavigation>
);

const Help = ({ onClick }: { onClick: () => void }) => (
  <MenuItem onClick={onClick}>
    <MenuItemContent compact>Help</MenuItemContent>
  </MenuItem>
);

const Templates = ({ onClick }: { onClick: () => void }) => (
  <MenuItem onClick={onClick}>
    <MenuItemContent compact>Templates</MenuItemContent>
  </MenuItem>
);

const MentiAcademy = () => (
  <MenuItemNextNavigation
    href="https://academy.mentimeter.com/"
    target="_blank"
    compact
  >
    Menti Academy
  </MenuItemNextNavigation>
);

const Trash = () => (
  <MenuItemNextNavigation compact href="/app/trash">
    Trash
  </MenuItemNextNavigation>
);

/**
 * Full menu
 */

const MobileMenu = () => {
  const { user } = useUser();
  const { subscriptions } = useSubscriptions(!user);
  const { isTrialing, hasCurrentSubscription } = billingRules(subscriptions);
  const {
    hideWorkspacePresentationPage,
    hasSharedTemplatesFeature,
    isLoadingFeatures,
  } = useFeatures();

  const isSharedTemplatesAllowed =
    !getIsMemberLite(user) && hasSharedTemplatesFeature;

  const { tryOpenDevelopmentToolsModal } = useDevelopmentToolsModal();
  const [showHelp, setShowHelp] = useState(false);
  const [showTemplates, setShowTemplates] = useState(false);
  const { workspacePresentationsFeatureTitle, isLoading: isLoadingTitle } =
    useWorkspacePresentationsFeatureTitle();

  if (isLoadingFeatures || !user || isLoadingTitle) return null;

  const canUpgrade = !hasCurrentSubscription || isTrialing;

  const handleShowTemplates = () => {
    setShowTemplates(true);
  };

  const handleShowHelp = () => {
    setShowHelp(true);
  };

  return (
    <Device.Match lessThan={3}>
      <MenuDrawer>
        <Box
          width="100%"
          gap="space12"
          extend={() => ({
            '@media print': {
              display: 'none',
            },
          })}
        >
          {/* Top section */}
          <MenuSection>
            <Home />
            <MyPresentations />
            <FindAvailableTeams />
            <SharedWithMe />
            <MenuCollapsable title="My workspace">
              <Billing />
              <WorkspaceSettings />
              <ManageMembers />
              <Requests />
              <Groups />
              <WorkspaceInsights />
            </MenuCollapsable>
            <MyProfile />
          </MenuSection>

          {/* Shared section */}
          <MenuSection>
            <MenuSectionHeading>
              {workspacePresentationsFeatureTitle}
            </MenuSectionHeading>
            {!hideWorkspacePresentationPage && (
              <Workspace>Workspace presentations</Workspace>
            )}
            {isSharedTemplatesAllowed && <SharedTemplates />}
          </MenuSection>

          {/* MISC section */}
          <MenuSection>
            {canUpgrade && <Plans />}
            <DrawerDismiss>
              <Help onClick={handleShowHelp} />
            </DrawerDismiss>
            <DrawerDismiss>
              <Templates onClick={handleShowTemplates} />
            </DrawerDismiss>
            <MentiAcademy />
            <Trash />
          </MenuSection>

          {/* Profile and logout */}
          <MenuSection gap="space6" alignItems="center" mb="space8">
            <Box alignItems="center" width="100%">
              <Avatar
                mb="space2"
                itemId={String(user.id)}
                name={user.name}
                initials={getNameInitials(user.name).toUpperCase()}
                type="user"
                color="black"
                profilePictureUrl={user.profile_picture?.presets?.preview?.url}
              />

              <Text truncate variant="ragnarBodyLg" color="text">
                {user.name}
              </Text>
              <DrawerDismiss>
                <Text
                  variant="ragnarBodySm"
                  color="textWeaker"
                  truncate
                  onClick={tryOpenDevelopmentToolsModal}
                >
                  {user.email}
                </Text>
              </DrawerDismiss>
            </Box>
            <Button onClick={logout} variant="outline" size="large">
              Log out
            </Button>
          </MenuSection>
        </Box>
      </MenuDrawer>

      <ModalRoot open={showHelp} onOpenChange={setShowHelp}>
        <HelpModal open={showHelp} />
      </ModalRoot>

      <ModalRoot open={showTemplates} onOpenChange={setShowTemplates}>
        <TemplateModal
          open={showTemplates}
          trackingContext="Dashboard mobile menu"
          modalOpeningContext="homeview"
          onDismiss={() => setShowTemplates(false)}
        />
      </ModalRoot>
    </Device.Match>
  );
};

export default MobileMenu;
