import { MentiError, captureException } from '@mentimeter/errors/sentry';
import debounce from 'lodash/debounce';
import { useCallback, useMemo, useRef, useState } from 'react';
import { postSearchRequest, postSearchResponse } from '@core-api/search/search';
import userCache from '@mentimeter/user';
import { usePageSearch } from './page-search';
import { trackSearched } from './tracking';
import type { SearchResult } from './types';

const MAX_SEARCH_LENGTH = 100;
const SEARCH_DEBOUNCE_TIME_MS = 450;
const DESIRED_RESULT_COUNT = 10;

export type SearchResultsState = 'idle' | 'has-results' | 'no-results';
export type SearchState = 'idle' | 'searching';

export interface OtherResult {
  title: string;
  category: string;
  url: string;
}

export function useSearch(
  { debounceTime }: { debounceTime: number } = {
    debounceTime: SEARCH_DEBOUNCE_TIME_MS,
  },
) {
  const searchPromise = useRef<Promise<Response> | null>(null);
  const [searchInput, setSearchInput] = useState('');
  const [searchState, setSearchState] = useState<SearchState>('idle');
  const [searchResultsState, setSearchResultsState] =
    useState<SearchResultsState>('idle');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [lastSearched, setLastSearched] = useState('');
  const [otherResults, setOtherResults] = useState<OtherResult[]>([]);
  const { searchPages } = usePageSearch();

  const onNewSearchResult = useCallback(
    (searchString: string, data: SearchResult[]) => {
      setSearchResults(data);
      setSearchState('idle');
      setSearchResultsState(data.length > 0 ? 'has-results' : 'no-results');

      const leftToFill = Math.max(0, DESIRED_RESULT_COUNT - data.length);
      if (leftToFill > 0) {
        const pages = searchPages(searchString);
        const pageResults = pages.map((p) => ({
          title: p.title,
          category: 'Page',
          url: p.url,
        }));

        setOtherResults(pageResults.slice(0, leftToFill));
      } else {
        setOtherResults([]);
      }
    },
    [searchPages],
  );

  const search = useMemo(() => {
    return debounce(async (searchString: string) => {
      try {
        setSearchState('searching');
        setLastSearched(searchString);

        const promise = fetch(
          postSearchRequest({
            query: searchString.slice(0, MAX_SEARCH_LENGTH),
            region: userCache.region,
            userAuth: userCache.getToken(),
          }),
        );

        searchPromise.current = promise;
        const result = await promise;
        const data = await postSearchResponse(result);

        if (searchPromise.current !== promise) {
          // Was cancelled
        } else {
          onNewSearchResult(searchString, data.results);
          trackSearched(data.results);
        }
      } catch (error) {
        setSearchState('idle');
        setSearchResultsState('no-results');

        captureException(
          new MentiError('searching in home', {
            feature: 'paid-user-growth',
            cause: error,
          }),
        );
      }
    }, debounceTime);
  }, [debounceTime, onNewSearchResult]);

  const cancelSearch = useCallback(() => {
    search.cancel();
    setSearchInput('');
    searchPromise.current = null;
    setSearchState('idle');
    setSearchResults([]);
    setSearchResultsState('idle');
  }, [search]);

  const scheduleSearch = useCallback(
    (searchString: string) => {
      if (!searchString) {
        cancelSearch();
      } else {
        setSearchInput(searchString);
        search(searchString);
      }
    },
    [cancelSearch, search],
  );

  return {
    // Search series + questions
    search: scheduleSearch,
    searchInput,
    searchState,
    searchResultsState,
    lastSearched,
    cancelSearch,
    searchResults,

    // Other results
    otherResults,
  };
}
